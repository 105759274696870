import React from 'react'
import ReactDOM from 'react-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core'
import { ThemeProvider } from 'styled-components'
import { App } from './components/App'
import './index.css'

const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#4D2140',
    },
    secondary: {
      main: '#7D2D32',
    }
  }
})

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

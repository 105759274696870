import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Grid, Card as MuiCard, Divider } from '@material-ui/core'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'

const StyledCard = styled(MuiCard)`
  max-width: 200px;
`

const StyledMedia = styled(CardMedia)`
  width: 200px;
  height: 200px;
  background-size: contain;
`

const StyledGrid = styled(Grid)`
  padding: ${({ theme }) => theme.spacing(2)}px;
`

const Ellipsis = styled(Typography)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

export function Card (props) {

  const [src, setSrc] = useState(null)

  useEffect(() => {
    const path = `${props.logo}?size=200`
    const fallback = 'notfound.png'
    const img = new Image()
    img.onload = () => setSrc(path)
    img.onerror = () => setSrc(fallback)
    img.src = path
  }, [props.logo])

  return (
    <StyledGrid item>
      <StyledCard>
        <StyledMedia
          image={src}
          title={props.platform}
        />
        <Divider />
        <CardContent>
          <Ellipsis gutterBottom variant='h6' align='center'>
            {props.platform}
          </Ellipsis>
          <Ellipsis variant='body1' align='center'>
            {props.type}
          </Ellipsis>
        </CardContent>
      </StyledCard>
    </StyledGrid>
  )
}
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

const StyledImage = styled.img`
  height: 50px;
  width: 50px;
`

export const TableImage = (props) => {
  const [src, setSrc] = useState(null)

  useEffect(() => {
    const path = `${props.src}?size=100`
    const fallback = 'notfound.png'
    const img = new Image()
    img.onload = () => setSrc(path)
    img.onerror = () => setSrc(fallback)
    img.src = path
  }, [props.src])

  return <StyledImage src={src} />
}
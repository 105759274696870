import React from 'react'
import styled from 'styled-components'
import { 
  Table as MuiTable, 
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from '@material-ui/core'
import { TableImage } from './TableImage'

const StyledTable = styled(TableContainer)`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(6)}px;
`

export function Table (props) {
  return (
    <StyledTable component={Paper}>
      <MuiTable>
        <TableHead>
          <TableRow>
            <TableCell>Logo</TableCell>
            <TableCell>Provider</TableCell>
            <TableCell>Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.networks.map((row) => (
            <TableRow key={row.platform}>
              <TableCell>
                <TableImage src={`${row.logo}?size=100`} />
              </TableCell>
              <TableCell>{row.platform}</TableCell>
              <TableCell>{row.type}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
    </StyledTable>
  )
}
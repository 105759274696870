import React, { useState } from 'react' 
import styled from 'styled-components'
import {
  Paper,
  Container,
  Grid,
  TextField,
  Button,
  Typography,
  FormHelperText,
  CircularProgress
} from '@material-ui/core'
import FavoriteIcon from '@material-ui/icons/Favorite'

import { Results } from './Results'

const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Title = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(4)}px;
`

const Image = styled.img`
  display: block;
` 

const Submit = styled.div`
  width: 380px;
  margin-top: ${({ theme }) => theme.spacing(6)}px;
  margin-bottom: ${({ theme }) => theme.spacing(6)}px;
`

const Input = styled(TextField)`
  margin-bottom: ${({ theme, error }) => error ? 0 : theme.spacing(2)}px;
`

const ErrorText = styled(FormHelperText)`
  margin-bottom: ${({ theme, error }) => error ? theme.spacing(2) : 0}px;
  text-align: center;
  transition: margin-bottom 0.5s;
`

const Progress = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.primary.main};
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
`

const ButtonWrapper = styled.div`
  position: relative;
`

const GradientButton = styled(Button)`
  background: linear-gradient(45deg, #4D2140 30%, #7D2D32 90%);
  border: 0;
  boxShadow: 0 3px 5px 2px rgba(125, 45, 50, .3);
  color: white;
  height: 48px;
  padding: 0 30px;

  &.Mui-disabled {
    background: ${({ theme }) => theme.palette.action.disabledBackground};
  }
`

const DescribeCard = styled(Paper)`
  max-width: 500px;
  min-height: 250px;
  margin: ${({ theme }) => theme.spacing(2)}px;
  padding: ${({ theme }) => theme.spacing(4)}px;
  background: ${({ theme, background }) => theme.palette[background].main};
  color: white;
`

const Footer = styled.div`
  margin-top: ${({ theme }) => theme.spacing(6)}px;
  margin-bottom: ${({ theme }) => theme.spacing(6)}px;
  display: flex;
  align-items: center;
`

const HeartIcon = styled(FavoriteIcon)`
  margin-left: ${({ theme }) => theme.spacing(2)}px;
  margin-right: ${({ theme }) => theme.spacing(2)}px;
`

export function App () {

  const [value, setValue] = useState('')
  const [error, setError] = useState('')
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(false)

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const handleSubmit = async () => {
    setError('')
    try {
      new URL(value)
    } catch {
      setError('Domain is invalid. Be sure to include the protocol (https://).')
      return
    }

    setLoading(true)
    setData(null)
    const res = await fetch(`/api?url=${value}`)
    if (!res.ok) {
      const text = await res.text()
      setError(text)
      setLoading(false)
      return
    }

    const json = await res.json()
    setData(json)
    setLoading(false)
  }

  return (
    <Container>
      <Center>
        <Image src='logo.png' alt='logo' width={200} height={200} />

        <Title variant='h4' align='center'>What infrastructure does your website depend on?</Title>
        <Typography align='center'>The modern web is complicated. Just because your website is hosted at one provider, doesn’t mean failures in other networks won’t impact your site. This tool will analyze your website and extract what infrastructure partners you depend on. The results might surprise you!</Typography>

        <Submit>
          <Input 
            fullWidth 
            placeholder='https://edgemesh.com' 
            label='Domain' 
            variant='filled' 
            disabled={loading}
            error={Boolean(error)}
            onChange={handleChange}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                handleSubmit()
              }
            }}
          />
          <ErrorText error={Boolean(error)}>{error}</ErrorText>
          <ButtonWrapper>
            <GradientButton 
              fullWidth 
              disabled={loading}
              onClick={handleSubmit}>
                Get My Dependencies
            </GradientButton>
            {loading && <Progress size={24} />}
          </ButtonWrapper>
        </Submit>

        {data && <Results {...data} />}

        <Grid 
          container
          direction='row'
          justify='center'
          alignItems='center'
        >
          <Grid item>
            <DescribeCard background='primary'>
              <Typography gutterBottom align='center' variant='h4'>How It Works</Typography>
              <Typography>
                This site is powered by Edgemesh Server and Cloudflare Workers. When you enter a website, Edgemesh Server will fetch a copy of your page and extract the unique endpoints required to render the page. From there, it will look up each endpoint’s IP address and determine what infrastructure platform and network are hosting the asset.
              </Typography>
            </DescribeCard>
          </Grid>

          <Grid item>
            <DescribeCard background='secondary'>
              <Typography gutterBottom align='center' variant='h4'>How Edgemesh Helps</Typography>
              <Typography>
                Edgemesh Server sites minimize infrastructure dependency creep by centralizing as many assets as possible on the global Cloudflare network - giving you a consistent and reliable page load every time. Edgemesh Client increases in browser cache hit rates - thereby reducing the overall dependency your visitors have on remote endpoints. To find out more how Edgemesh can help, visit us as <a href='https://edgemesh.com' target='_blank' rel='noreferrer'>https://edgemesh.com</a>.
              </Typography>
            </DescribeCard>
          </Grid>
        </Grid>

        <Footer>
          Built with Edgemesh™ Server & Cloudflare Workers <HeartIcon fontSize='small' color='secondary' /> ©2021 Edgemesh Corporation: Attribution Required.
        </Footer>

      </Center>
    </Container>
  )
}

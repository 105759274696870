import React, { useState } from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import { 
  ToggleButton,
  ToggleButtonGroup
} from '@material-ui/lab'

import AppsIcon from '@material-ui/icons/Apps'
import TableChartIcon from '@material-ui/icons/TableChart'

import { Location } from './Location'
import { Card } from './Card'
import { Table } from './Table'

const StyledGrid = styled(Grid)`
  margin-top: ${({ theme }) => theme.spacing(6)}px;
`

const Container = styled.div`
  display: flex;
  width: 100%;
  justifyContent: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.spacing(8)}px;
`

export function Results (props) {
  const [viewMode, setViewMode] = useState('grid')
  return (
    <Container>
      <ToggleButtonGroup
        value={viewMode}
        onChange={(_, value) => setViewMode(value)}
        exclusive
      >
        <ToggleButton value='grid'>
          <AppsIcon />
        </ToggleButton>
        <ToggleButton value="table">
          <TableChartIcon />
        </ToggleButton>
      </ToggleButtonGroup>

      {viewMode === 'grid' ? (
        <StyledGrid 
          container
          direction='row'
          justify='center'
          alignItems='center'
        >
          {props.networks.map(network => (
            <Card key={network.platform} {...network} />
          ))}
        </StyledGrid>
      ) : (
        <Table networks={props.networks} />
      )}

      <Location {...props.location} />
    </Container>
  )
}
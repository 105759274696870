import React from 'react'
import styled from 'styled-components'
import { Paper } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import RoomIcon from '@material-ui/icons/Room'

const StyledPaper = styled(Paper)`
  max-width: 300px;
  padding-top: ${({ theme }) => theme.spacing(2)}px;
  padding-right: ${({ theme }) => theme.spacing(4)}px;
  padding-bottom: ${({ theme }) => theme.spacing(2)}px;
  padding-left: ${({ theme }) => theme.spacing(4)}px;
  margin-top: ${({ theme }) => theme.spacing(6)}px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Icon = styled(RoomIcon)`
  margin-right: ${({ theme }) => theme.spacing(4)}px;
`

const Column = styled.div``

export function Location (props) {
  return (
    <StyledPaper elevation={2}>
      <Row>
        <Icon />
        <Column>
          <Typography variant='body1' component='p'>
            {props.city}, {props.region} 
          </Typography>
          <Typography variant='body1' component='p'>
            {props.zip} {props.country}
          </Typography>
        </Column>
      </Row>
    </StyledPaper>
  )
}